import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { useGetApiAdministrationBaserateandsupplementQuery } from "api/generatedApi";
import { useAdminBaseRateAndSupplements } from "./use-admin-base-rate-and-supplements";
import { useTranslation } from "react-i18next";
import { StyledAdminContent } from "../admin.styled";

export const AdminBaseRateAndSupplements = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetApiAdministrationBaserateandsupplementQuery();

  const openEditBaseRateAndSupplements = useAdminBaseRateAndSupplements({ data });

  if (isLoading) return null;

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h5">{t("admin.baseRateAndSupplements.title")}</Typography>
        <Button variant="contained" onClick={openEditBaseRateAndSupplements}>
          {t("admin.baseRateAndSupplements.button")}
        </Button>
      </Box>

      <List>
        <ListItem>
          <ListItemText>
            {t("admin.baseRateAndSupplements.regulationPercentage")}: {data?.baseRateRegulationPercentage?.value}
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            {t("admin.baseRateAndSupplements.indirectTimeSupplementPercentage")}: {data?.indirectTimeSupplementPercentage?.value}
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            {t("admin.baseRateAndSupplements.siteSpecificTimeSupplementPercentage")}: {data?.siteSpecificTimeSupplementPercentage?.value}
          </ListItemText>
        </ListItem>
      </List>

      <Typography>{t("admin.baseRateAndSupplements.baseRateIntervals")}:</Typography>
      <List>
        {data?.baseRateIntervals?.map((item, index) => {
          return (
            <StyledAdminContent key={index}>
              <ListItem>
                <ListItemText>
                  {t("admin.baseRateAndSupplements.baseRateDkr")}: {item.baseRateDkr?.value}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t("admin.dateIntervalStart")}: {item.dateInterval?.startDate}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t("admin.dateIntervalEnd")}: {item.dateInterval?.endDate}
                </ListItemText>
              </ListItem>
            </StyledAdminContent>
          );
        })}
      </List>

      <Typography>{t("admin.baseRateAndSupplements.personalTimeSupplementIntervals")}:</Typography>
      <List>
        {data?.personalTimeSupplementIntervals?.map((item, index) => {
          return (
            <StyledAdminContent key={index}>
              <ListItem>
                <ListItemText>
                  {t("admin.baseRateAndSupplements.personalTimeSupplementPercentage")}: {item.personalTimeSupplementPercentage?.value}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t("admin.dateIntervalStart")}: {item.dateInterval?.startDate}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t("admin.dateIntervalEnd")}: {item.dateInterval?.endDate}
                </ListItemText>
              </ListItem>
            </StyledAdminContent>
          );
        })}
      </List>
    </Box>
  );
};
