import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { useGetApiAdministrationProfitshareQuery } from "api/generatedApi";
import { StyledAdminContent, StyledListWrapper } from "../admin.styled";
import { useAdminProfitShare } from "./use-admin-profit-share";
import { useTranslation } from "react-i18next";

export const AdminProfitShare = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetApiAdministrationProfitshareQuery();

  const openEditProfitShare = useAdminProfitShare({ data });

  if (isLoading) return null;

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h5">{t("admin.profitShare.title")}</Typography>
        <Button variant="contained" onClick={openEditProfitShare}>
          {t("admin.profitShare.button")}
        </Button>
      </Box>

      <List>
        {data?.profitShareAgreements?.map((item, index) => {
          return (
            <StyledListWrapper key={index}>
              <ListItem>
                <ListItemText>
                  {t("admin.dateIntervalStart")}: {item.profitShareDateInterval?.startDate}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t("admin.dateIntervalEnd")}: {item.profitShareDateInterval?.endDate}
                </ListItemText>
              </ListItem>
              <Typography>{t("admin.profitShare.profitShareEntries")}:</Typography>
              {item.profitShareEntries?.map((entry, index) => {
                return (
                  <StyledAdminContent key={index}>
                    <ListItem>
                      <ListItemText>
                        {t("admin.profitShare.profitSharePercentage")}: {entry.profitSharePercentage?.value}
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText>
                        {t("admin.profitShare.profitShareText")}: {entry.profitShareText?.value}
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText>
                        {t("admin.profitShare.profitShareValue")}: {entry.profitShareValue?.value}
                      </ListItemText>
                    </ListItem>
                  </StyledAdminContent>
                );
              })}
            </StyledListWrapper>
          );
        })}
      </List>
    </Box>
  );
};
