import React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { AdminBaseRateAndSupplements } from "components/page/admin/admin-base-rate-and-supplements/admin-base-rate-and-supplements";
import { AdminProfitShare } from "components/page/admin/admin-profit-share/admin-profit-share";
import { AdminSupplements } from "components/page/admin/admin-supplements/admin-supplements";
import { useTranslation } from "react-i18next";
import { useScreenSize, ScreenSizeEnum } from "shared/use-screen-size";

export const Admin = () => {
  const { t } = useTranslation();

  const { screenSize } = useScreenSize();
  const padHorizontal = screenSize === ScreenSizeEnum.Mobile ? 2 : 8;
  const padVertial = screenSize === ScreenSizeEnum.Mobile ? 4 : 8;

  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, tabValue: number) => {
    setSelectedTab(tabValue);
  };

  const tabsConfig = [{ content: <AdminBaseRateAndSupplements /> }, { content: <AdminProfitShare /> }, { content: <AdminSupplements /> }];

  return (
    <Box pt={padVertial} px={padHorizontal} pb={4} sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "start", overflow: "auto" }}>
      <Typography color={"primary.main"} variant="h5" pb={4}>
        {t("admin.title")}
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="basic tabs example">
          <Tab label={t("admin.tabs.baseRateAndSupplements")} />
          <Tab label={t("admin.tabs.profitShare")} />
          <Tab label={t("admin.tabs.supplements")} />
        </Tabs>
      </Box>
      {tabsConfig.map(
        (tab, index) =>
          selectedTab === index && (
            <Box sx={{ overflowY: "hidden" }} key={index}>
              <Box sx={{ p: 2, overflow: "auto", height: "100%" }}>{tab.content}</Box>
            </Box>
          )
      )}
    </Box>
  );
};
