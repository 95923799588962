import {
  FolderSupplementResponse,
  GetWorkItemMaterialPreviewSupplementOperationRequest,
  GetWorkItemOperationPreviewSupplementRequest,
  MaterialMountingResponse,
  MaterialSupplementOperationRequest,
  MaterialSupplementRequest,
  SupplementOperationResponse,
  SupplementResponse,
  WorkItemSupplementOperationResponse,
  WorkItemSupplementResponse,
} from "api/generatedApi";
import React from "react";
import { sortCompareString } from "utils/compares";
import { noMountingCodeSelected } from "./measurements.helpers";

export interface ExtendedSupplementOperationResponse extends SupplementOperationResponse {
  amount: number;
}

export interface ExtendedSupplementResponse extends SupplementResponse {
  checked: boolean;
}

export interface ExtendedMaterialSupplementOperationRequest extends MaterialSupplementOperationRequest {
  text: string;
}

export function UseMapWorkItem() {
  const mapMountingCode = (data?: MaterialMountingResponse): number | undefined => {
    if (!data) return undefined;
    if (data.mountingCode === noMountingCodeSelected.mountingCode) return undefined;
    return data.mountingCode && data.mountingCode > 0 ? data.mountingCode : undefined;
  };
  const mapMountingText = (data?: MaterialMountingResponse): string => {
    if (!data) return "";
    if (data.mountingCode === noMountingCodeSelected.mountingCode) return "";
    return data.mountingCode && data.mountingCode > 0 && data.text ? data.text : "";
  };
  const mapSupplementOperations = (data?: ExtendedSupplementOperationResponse[]): WorkItemSupplementOperationResponse[] | undefined => {
    if (!data) return undefined;
    return data
      .filter((so) => so?.text && so?.amount > 0)
      ?.map((os) => {
        if (os.text) {
          return {
            text: os.text,
            amount: os.amount,
            catalogSupplementOperationId: os.supplementOperationId,
            operationNumber: os.operationNumber,
            operationTimeMilliseconds: os.operationTimeMilliseconds,
            type: os.type,
          };
        }
        return { text: "", amount: 0 };
      });
  };

  const mapPreselectedSupplementOperations = React.useCallback((data?: ExtendedSupplementOperationResponse[], preSelectedData?: WorkItemSupplementOperationResponse[]): ExtendedSupplementOperationResponse[] | undefined => {
    if (!data || !preSelectedData) return undefined;

    if (preSelectedData && data && data.length > 0) {
      const newArray = [...data];
      preSelectedData.forEach((preSelected) => {
        const index = data.findIndex((supplementOperation) => supplementOperation?.supplementOperationId === preSelected?.catalogSupplementOperationId);
        if (index !== -1) {
          newArray[index].amount = preSelected?.amount ?? 0;
        }
      });
      return newArray;
    }
  }, []);

  const mapSupplementOperationsRequests = (data?: ExtendedSupplementOperationResponse[]): MaterialSupplementOperationRequest[] => {
    if (!data) return [];
    return data
      .filter((os) => os.amount > 0)
      ?.map<MaterialSupplementOperationRequest>((os) => {
        if (os.supplementOperationId) {
          return { supplementOperationId: os.supplementOperationId, amount: os.amount };
        }
        return { supplementOperationId: "", amount: 0 };
      });
  };

  const mapExtendedSupplementOperationsRequests = (data?: ExtendedSupplementOperationResponse[]): ExtendedMaterialSupplementOperationRequest[] => {
    if (!data) return [];
    return data
      .filter((os) => os.amount > 0)
      ?.map<ExtendedMaterialSupplementOperationRequest>((os) => {
        if (os.supplementOperationId) {
          return { supplementOperationId: os.supplementOperationId, amount: os.amount, text: os.text ?? "" };
        }
        return { supplementOperationId: "", amount: 0, text: "" };
      });
  };

  const mapSupplementsToPreviewRequests = (data?: ExtendedSupplementResponse[]): GetWorkItemOperationPreviewSupplementRequest[] => {
    if (!data) return [];
    return data
      .filter((supplement) => supplement.checked)
      .map<GetWorkItemOperationPreviewSupplementRequest>((supplement) => {
        if (supplement.supplementId) {
          return { supplementId: supplement.supplementId };
        }
        return { supplementId: "" };
      });
  };

  const mapSupplementOperationsToPreviewRequests = (data?: ExtendedSupplementOperationResponse[]): GetWorkItemMaterialPreviewSupplementOperationRequest[] => {
    if (!data) return [];
    return data
      .filter((os) => os.amount > 0)
      .map<GetWorkItemMaterialPreviewSupplementOperationRequest>((os) => {
        if (os.supplementOperationId) {
          return {
            supplementOperationId: os.supplementOperationId,
            amount: os.amount,
            operationNumber: os.operationNumber,
            operationTimeMilliseconds: os.operationTimeMilliseconds,
            text: os.text,
            type: os.type || (os as any)["operationType"],
          };
        }
        return { supplementOperationId: "", amount: 0 };
      });
  };

  const mapSupplementResponses = (data?: ExtendedSupplementResponse[]): WorkItemSupplementResponse[] | undefined => {
    if (!data) return undefined;
    return data.filter((incon) => incon?.supplementText && incon?.checked)?.map((inco) => (inco as WorkItemSupplementResponse) ?? undefined);
  };
  const mapExtendedSupplementsResponses = (data?: SupplementResponse[], preSelectedIds?: FolderSupplementResponse[]): ExtendedSupplementResponse[] | undefined => {
    if (!data) return undefined;
    const dataWithExtraItems = [...data];
    preSelectedIds?.forEach((x) => {
      if (!data.find((d) => d.supplementNumber === x.supplementNumber)) {
        dataWithExtraItems.push(x);
      }
    });

    const sorted = [...dataWithExtraItems].sort((a, b) => sortCompareString("desc", a.supplementNumber, b.supplementNumber));
    const mapped = sorted?.map((incon) => {
      const item = {
        supplementId: incon.supplementId,
        supplementNumber: incon.supplementNumber,
        supplementText: incon.supplementText,
        checked: !!incon.supplementId && !!preSelectedIds?.find((preSelectedId) => preSelectedId.supplementNumber === incon.supplementNumber),
      } as ExtendedSupplementResponse;
      return item;
    });
    return mapped;
  };
  const mapSupplementRequests = (data?: ExtendedSupplementResponse[]): MaterialSupplementRequest[] => {
    if (!data) return [];
    const mapped = data
      .filter((sup) => sup?.checked)
      ?.map((sup) => {
        return { supplementId: sup.supplementId } as MaterialSupplementRequest;
      });
    return mapped;
  };

  const mapPreselectedSupplementRequests = (data?: WorkItemSupplementResponse[]) => {
    if (!data) return [];
    return data.map((supplement) => {
      return { supplementId: supplement.catalogSupplementId, folderSupplementId: supplement.supplementId } as MaterialSupplementRequest;
    });
  };

  const mapSupplementTexts = (data?: ExtendedSupplementResponse[]): string[] => {
    if (!data) return [];
    const mapped = data
      .filter((sup) => sup?.checked)
      ?.map((sup) => {
        return sup.supplementText ?? sup.supplementNumber ?? "";
      });
    const filtered = mapped.filter((t) => t !== "");
    return filtered;
  };
  return {
    mapMountingCode,
    mapMountingText,
    mapSupplementOperations,
    mapPreselectedSupplementOperations,
    mapSupplementOperationsRequests,
    mapExtendedSupplementOperationsRequests,
    mapSupplementResponses,
    mapExtendedSupplementsResponses,
    mapSupplementRequests,
    mapPreselectedSupplementRequests,
    mapSupplementsToPreviewRequests,
    mapSupplementOperationsToPreviewRequests,
    mapSupplementTexts,
  };
}
