import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { useGetApiAdministrationSupplementsQuery } from "api/generatedApi";
import { StyledAdminContent } from "../admin.styled";
import { useAdminSupplements } from "./use-admin-supplements";
import { useTranslation } from "react-i18next";

export const AdminSupplements = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetApiAdministrationSupplementsQuery();

  const openEditSupplements = useAdminSupplements({ data });

  if (isLoading) return null;

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h5">{t("admin.supplements.title")}</Typography>
        <Button variant="contained" onClick={openEditSupplements}>
          {t("admin.supplements.button")}
        </Button>
      </Box>

      <List>
        {data?.supplementEntries?.map((item, index) => {
          return (
            <StyledAdminContent key={index}>
              <ListItem sx={{ backgroundColor: "grey" }}>
                <ListItemText>
                  {t("admin.supplements.supplementId")}: {item.supplementId?.value}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t("admin.supplements.supplementNumber")}: {item.supplementNumber?.value}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t("admin.supplements.supplementPercentage")}: {item.supplementPercentage?.value}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t("admin.supplements.supplementText")}: {item.supplementText?.value}
                </ListItemText>
              </ListItem>
            </StyledAdminContent>
          );
        })}
      </List>
    </Box>
  );
};
