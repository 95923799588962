import React from "react";
import { Button, Dialog, DialogActions, DialogContent, IconButton, TextField, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { UpdateBaseRateAndSupplementConfigRequest } from "api/generatedApi";
import DialogTitleStyled from "components/shared/dialog/dialog-title-styled";
import { useTranslation } from "react-i18next";
import { DialogBaseProps } from "shared/dialog/types";
import { DialogList, FlexBetweenCenter, StyledDialogTextfield, StyledListButton, defaultDate, defaultValue } from "../admin.styled";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";

interface Props extends DialogBaseProps {
  data?: UpdateBaseRateAndSupplementConfigRequest;
  onSubmit: (data: UpdateBaseRateAndSupplementConfigRequest) => void;
}

export const AdminBaseRateAndSupplementsDialog = (props: Props) => {
  const { data } = props;
  const { t } = useTranslation();

  const {
    formState: { isSubmitting },
    register,
    handleSubmit,
    getValues,
    control,
  } = useForm<UpdateBaseRateAndSupplementConfigRequest>({
    mode: "all",
    defaultValues: {
      baseRateIntervals: data?.baseRateIntervals,
      personalTimeSupplementIntervals: data?.personalTimeSupplementIntervals,
    },
  });

  const { append: appendBaseRate, remove: removeBaseRate } = useFieldArray({
    control,
    name: "baseRateIntervals",
  });

  const { append: appendPersonal, remove: removePersonal } = useFieldArray({
    control,
    name: "personalTimeSupplementIntervals",
  });

  const onSubmit: SubmitHandler<UpdateBaseRateAndSupplementConfigRequest> = async (data) => {
    await props.onSubmit(data);

    return new Promise<void>((resolve) => {
      resolve();
    });
  };

  if (!data) return null;

  return (
    <Dialog maxWidth="md" open={props.isOpen}>
      <DialogTitleStyled title={t("admin.baseRateAndSupplements.edit.title")} onClose={props.onClose} isOpen={props.isOpen} handleIconClose={props.onClose} />
      <DialogContent sx={{ p: 3, pr: 5 }}>
        <FlexBetweenCenter>
          <TextField
            {...register("baseRateRegulationPercentage.value", { valueAsNumber: true })}
            type="number"
            variant="filled"
            label={t("admin.baseRateAndSupplements.regulationPercentage")}
            defaultValue={data.baseRateRegulationPercentage?.value}
            inputRef={(input) => input?.focus()}
          />
          <TextField
            {...register("indirectTimeSupplementPercentage.value", { valueAsNumber: true })}
            type="number"
            variant="filled"
            label={t("admin.baseRateAndSupplements.indirectTimeSupplementPercentage")}
            defaultValue={data.indirectTimeSupplementPercentage?.value}
          />
          <TextField
            {...register("siteSpecificTimeSupplementPercentage.value", { valueAsNumber: true })}
            type="number"
            variant="filled"
            label={t("admin.baseRateAndSupplements.siteSpecificTimeSupplementPercentage")}
            defaultValue={data.siteSpecificTimeSupplementPercentage?.value}
          />
        </FlexBetweenCenter>
        {getValues().baseRateIntervals && (
          <React.Fragment>
            <Typography>{t("admin.baseRateAndSupplements.baseRateIntervals")}</Typography>
            {getValues().baseRateIntervals?.map((item, index) => {
              return (
                <DialogList key={index}>
                  <IconButton onClick={() => removeBaseRate(index)}>
                    <DeleteIcon />
                  </IconButton>
                  <StyledDialogTextfield
                    {...register(`baseRateIntervals.${index}.baseRateDkr.value`, { valueAsNumber: true })}
                    type="number"
                    variant="filled"
                    label={t("admin.baseRateAndSupplements.baseRateDkr")}
                    defaultValue={item.baseRateDkr?.value}
                  />
                  <StyledDialogTextfield {...register(`baseRateIntervals.${index}.dateInterval.startDate`)} variant="filled" label={t("admin.dateIntervalStart")} defaultValue={item.dateInterval?.startDate} />
                  <StyledDialogTextfield {...register(`baseRateIntervals.${index}.dateInterval.endDate`)} variant="filled" label={t("admin.dateIntervalEnd")} defaultValue={item.dateInterval?.endDate} />
                </DialogList>
              );
            })}
            <StyledListButton variant="contained" color="primary" onClick={() => appendBaseRate({ baseRateDkr: { value: defaultValue }, dateInterval: { startDate: defaultDate, endDate: defaultDate } })}>
              {t("admin.addToList")}
            </StyledListButton>
          </React.Fragment>
        )}
        {getValues()?.personalTimeSupplementIntervals && (
          <React.Fragment>
            <Typography>{t("admin.baseRateAndSupplements.personalTimeSupplementIntervals")}</Typography>
            {getValues().personalTimeSupplementIntervals?.map((item, index) => {
              return (
                <DialogList key={index}>
                  <IconButton onClick={() => removePersonal(index)}>
                    <DeleteIcon />
                  </IconButton>
                  <StyledDialogTextfield
                    {...register(`personalTimeSupplementIntervals.${index}.personalTimeSupplementPercentage.value`, { valueAsNumber: true })}
                    type="number"
                    variant="filled"
                    label={t("admin.baseRateAndSupplements.personalTimeSupplementPercentage")}
                    defaultValue={item.personalTimeSupplementPercentage?.value}
                  />
                  <StyledDialogTextfield {...register(`personalTimeSupplementIntervals.${index}.dateInterval.startDate`)} variant="filled" label={t("admin.dateIntervalStart")} defaultValue={item.dateInterval?.startDate} />
                  <StyledDialogTextfield {...register(`personalTimeSupplementIntervals.${index}.dateInterval.endDate`)} variant="filled" label={t("admin.dateIntervalEnd")} defaultValue={item.dateInterval?.endDate} />
                </DialogList>
              );
            })}
            <StyledListButton variant="contained" color="primary" onClick={() => appendPersonal({ personalTimeSupplementPercentage: { value: defaultValue }, dateInterval: { startDate: defaultDate, endDate: defaultDate } })}>
              {t("admin.addToList")}
            </StyledListButton>
          </React.Fragment>
        )}
      </DialogContent>
      <DialogActions sx={{ pr: 3 }}>
        <Button variant="contained" sx={{ width: "160px" }} color="primary" disabled={isSubmitting} onClick={handleSubmit(onSubmit)}>
          {t("common.saveAndClose")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
